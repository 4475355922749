import React, { PureComponent, ReactElement } from 'react';
import {
  Route,
  Router,
  Switch,
  Redirect,
  matchPath,
} from 'react-router-dom';
import * as singleSpa from 'single-spa';
import Cookies from 'js-cookie';

import { Parcel } from '@pushwoosh/single-spa';
import { GlobalStyles } from '@pushwoosh/kit-typography';

import { AppCodeRedirect } from './components/appcode-redirect';
import { ApplicationConnect } from './components/application-connect';
import { Application, ApplicationWithWidget } from './components/application';
import { MenuPreloader } from './preloaders/menu-preloader';
import { NavbarPreloader } from './preloaders/navbar-preloader';
import { MenuFallback } from './fallbacks/menu-fallback';
import { NavbarFallback } from './fallbacks/navbar-fallback';

import {
  RootBox,
  RootContent,
  RootInner,
  RootMenu,
  RootMenuInner,
  RootNavbar,
} from './root.styles';
import type { RootProps, RootState } from './root.types';
import {
  checkOnboardingNeeded,
  checkShowWelcomeScreen,
  getApplicationsList,
  goToMyProjectHomePage,
  identifyUserInLOU,
} from '~/src/helpers/app.helpers';
import { UserMetricsCollector } from './helpers/sendStatisticsOnUserActions';
import { identifyMixpanelSession, initMixpanelSession } from './helpers/mixpanel';

export class Root extends PureComponent<RootProps, RootState> {
  private metricsCollector = new UserMetricsCollector();

  private unlisten: () => void;

  private prevPathname: string;

  constructor(props: RootProps) {
    super(props);
    this.unlisten = (): void => { };

    this.state = {
      brokenMap: {},
      session: null,
    };

    this.prevPathname = '';
  }

  componentDidMount(): void {
    initMixpanelSession();
    window.addEventListener('louAssistLoaded', this.handleUserIdentify);
    const { sessionStore, history, grpcClient } = this.props;

    singleSpa.addErrorHandler(this.handleSingleSpaError);
    singleSpa.start();

    sessionStore.subscribe(async (session) => {
      this.setState({ session });
      this.metricsCollector.setAccountId(session.account.id);

      identifyMixpanelSession(session.user, session.account);
      Cookies.set(
        'UserRestriction',
        session.account.productType,
        {
          domain: '.pushwoosh.com',
          path: '/',
        },
      );

      Cookies.set(
        'UserRegistrationDate',
        session.account.registrationDate.toISOString(),
        {
          domain: '.pushwoosh.com',
          path: '/',
        },
      );

      // check if the account is new and redirect to plan selection if it is
      const applicationsList = await getApplicationsList(grpcClient);
      const checkOnboardingNeededResponse = await checkOnboardingNeeded(grpcClient);
      const showWelcomePage = await checkShowWelcomeScreen(session, applicationsList);

      const journeyRoutes = ['/journeys/:applicationCode/list', '/journeys/list'];
      const journeysMatch = !!matchPath(window.location.pathname, { path: journeyRoutes });

      if (checkOnboardingNeededResponse.getIsActive()) {
        if (checkOnboardingNeededResponse.getShowSurvey()) {
          history.push('/survey');
        }
        if (checkOnboardingNeededResponse.getPaywallPlan()) {
          history.push('/subscription-select');
        }
        if (checkOnboardingNeededResponse.getWelcomeScreen() && showWelcomePage && !journeysMatch && !checkOnboardingNeededResponse.getShowSurvey()) {
          await goToMyProjectHomePage(grpcClient, history, applicationsList);
        }
      }
    });

    this.unlisten = history.listen((_location) => {
      const currentPathname = _location.pathname;

      if (currentPathname !== this.prevPathname) {
        this.metricsCollector.onRouteChange();
        this.prevPathname = currentPathname;
      }
    });

    this.metricsCollector.subscribe();
  }

  componentDidCatch(error: unknown): void {
    console.error('componentDidCatch', error);
    document.body.classList.add('is-crashed');
  }

  componentWillUnmount(): void {
    window.removeEventListener('louAssistLoaded', this.handleUserIdentify);
    this.metricsCollector.unsubscribe();
    this.unlisten();
  }

  handleSingleSpaError = (error: singleSpa.AppError): void => {
    const { appOrParcelName } = error;
    if (appOrParcelName) {
      this.setState(({ brokenMap }) => ({
        brokenMap: {
          ...brokenMap,
          [appOrParcelName]: true,
        },
      }));
    }

    console.error('handleSingleSpaError', error);
  };

  handleUserIdentify = (): void => {
    const { session } = this.state;
    if (session !== null) {
      identifyUserInLOU(session);
    }
  };

  render(): JSX.Element {
    const { brokenMap } = this.state;

    const {
      history,
      httpClient,
      sessionStore,
      rpcClient,
      grpcClient,
      grpcBridge,
      billingGrpcClient,
      journeyGrpcClient,
      pushwooshSPAContext: spaContext,
    } = this.props;

    return (
      <React.StrictMode>
        <Router history={history}>
          <RootBox>
            <GlobalStyles />
            <Switch>
              <Route
                path={[
                  '/projects',
                  '/survey',
                  '/subscription-select',
                  '/subscription-purchase',
                  '/subscription-finish',
                  '/welcome-screen',
                  '/products/select',
                  '/products/buy-product',
                  '/applications/:applicationCode/email-content/:any',
                  '/applications/:applicationCode/statistics/analytics-dashboard/dashboards/:dashboardCode/:any',
                ]}
              />
              <Route
                path="/applications/app-code/*"
                render={(): ReactElement => (
                  <AppCodeRedirect grpcClient={grpcClient} />
                )}
              />
              <Route path={['/', '/applications', '/groups']} exact />
              <Route path={['/']}>
                <RootMenu>
                  <RootMenuInner>
                    <Application
                      name="@pushwoosh/classic-menu"
                      isBroken={Boolean(brokenMap['@pushwoosh/classic-menu'])}
                      customProps={{
                        spaContext,
                        history,
                        httpClient,
                        grpcClient,
                        sessionStore,
                      }}
                      preloader={(<MenuPreloader />)}
                      fallback={(<MenuFallback />)}
                    />
                  </RootMenuInner>
                </RootMenu>
              </Route>
            </Switch>
            <Route
              path={[
                '/projects',
                '/products/select',
                '/products/buy-product',
                '/applications/:applicationCode/email-content/:any',
              ]}
            >
              <RootNavbar>
                <Application
                  name="@pushwoosh/navbar"
                  isBroken={Boolean(brokenMap['@pushwoosh/navbar'])}
                  customProps={{
                    spaContext,
                    history,
                    httpClient,
                    sessionStore,
                    rpcClient,
                  }}
                  preloader={(<NavbarPreloader />)}
                  fallback={(<NavbarFallback />)}
                />
              </RootNavbar>
            </Route>
            <RootInner>
              <RootContent>
                <Switch>
                  <Route path={['/', '/applications']} exact>
                    <Redirect to="/projects" />
                  </Route>
                  <Route path={['/groups']} exact>
                    <Redirect to="/projects/groups" />
                  </Route>
                  <Route path={['/journeys']}>
                    <ApplicationWithWidget
                      widgetState="hide"
                      name="@pushwoosh/customer-journey"
                      isBroken={Boolean(brokenMap['@pushwoosh/customer-journey'])}
                      customProps={{
                        spaContext,
                        history,
                        httpClient,
                        sessionStore,
                        grpcClient,
                        rpcClient,
                        grpcBridge,
                        journeyGrpcClient,
                      }}
                    />
                  </Route>
                  <Route path="/accounts">
                    <ApplicationWithWidget
                      widgetState="show"
                      name="@pushwoosh/accounts"
                      isBroken={Boolean(brokenMap['@pushwoosh/accounts'])}
                      customProps={{
                        spaContext,
                        history,
                        httpClient,
                        sessionStore,
                        grpcClient,
                        billingGrpcClient,
                      }}
                    />
                  </Route>
                  <Route path="/projects">
                    <ApplicationWithWidget
                      widgetState="show"
                      name="@pushwoosh/applications-list"
                      isBroken={Boolean(brokenMap['@pushwoosh/applications-list'])}
                      customProps={{
                        spaContext,
                        history,
                        httpClient,
                        sessionStore,
                        grpcClient,
                      }}
                    />
                  </Route>
                  <Route path="/applications/:applicationCode/user-explorer">
                    <ApplicationWithWidget
                      widgetState="show"
                      name="@pushwoosh/user-explorer"
                      isBroken={Boolean(brokenMap['@pushwoosh/user-explorer'])}
                      customProps={{
                        spaContext,
                        history,
                        httpClient,
                        sessionStore,
                        grpcClient,
                        grpcBridge,
                      }}
                    />
                  </Route>
                  <Route path="/applications/:applicationCode/home">
                    <ApplicationWithWidget
                      widgetState="show"
                      name="@pushwoosh/home-page"
                      isBroken={Boolean(brokenMap['@pushwoosh/home-page'])}
                      customProps={{
                        spaContext,
                        history,
                        httpClient,
                        sessionStore,
                        grpcClient,
                        billingGrpcClient,
                      }}
                    />
                  </Route>
                  <Route
                    path={[
                      '/applications/:applicationCode/email-content',
                      '/applications/:applicationCode/email/select',
                    ]}
                    render={(props): any => (
                      <ApplicationWithWidget
                        widgetState="hide"
                        name="@pushwoosh/email-builder"
                        isBroken={Boolean(brokenMap['@pushwoosh/email-builder'])}
                        customProps={{
                          spaContext,
                          history,
                          httpClient,
                          sessionStore,
                          rpcClient,
                          grpcClient,
                          grpcBridge,
                          listOnly: props.match.path.includes('/select'),
                        }}
                      />
                    )}
                  />
                  <Route
                    path={[
                      '/applications/:applicationCode/push-presets',
                      '/applications/:applicationCode/sms-presets',
                    ]}
                  >
                    <ApplicationWithWidget
                      widgetState="show"
                      name="@pushwoosh/push-preset-form"
                      isBroken={Boolean(brokenMap['@pushwoosh/push-preset-form'])}
                      customProps={{
                        spaContext,
                        history,
                        httpClient,
                        sessionStore,
                        rpcClient,
                        grpcClient,
                        grpcBridge,
                      }}
                    />
                  </Route>
                  <Route path="/applications/:applicationCode/one-time-messages">
                    <ApplicationWithWidget
                      widgetState="show"
                      name="@pushwoosh/one-time-messages"
                      isBroken={Boolean(brokenMap['@pushwoosh/one-time-messages'])}
                      customProps={{
                        spaContext,
                        sessionStore,
                      }}
                    />
                  </Route>
                  <Route path="/applications/:applicationCode/whatsapp">
                    <ApplicationWithWidget
                      widgetState="show"
                      name="@pushwoosh/whatsapp"
                      isBroken={Boolean(brokenMap['@pushwoosh/whatsapp'])}
                      customProps={{
                        spaContext,
                      }}
                    />
                  </Route>
                  <Route path="/applications/:applicationCode/setup-wizard">
                    <ApplicationWithWidget
                      widgetState="show"
                      name="@pushwoosh/setup-wizard"
                      isBroken={Boolean(brokenMap['@pushwoosh/setup-wizard'])}
                      customProps={{
                        spaContext,
                        history,
                        httpClient,
                        sessionStore,
                        grpcClient,
                        billingGrpcClient,
                      }}
                    />
                  </Route>
                  <Route path="/applications/:applicationCode/statistics/application-statistics">
                    <ApplicationConnect
                      widgetState="show"
                      name="@pushwoosh/application-statistics"
                      isBroken={Boolean(brokenMap['@pushwoosh/application-statistics'])}
                      customProps={{
                        spaContext,
                        history,
                        httpClient,
                        sessionStore,
                        rpcClient,
                        grpcClient,
                      }}
                    />
                  </Route>
                  <Route path="/groups/:groupCode/statistics/application-statistics">
                    <ApplicationConnect
                      widgetState="show"
                      name="@pushwoosh/application-statistics"
                      isBroken={Boolean(brokenMap['@pushwoosh/application-statistics'])}
                      customProps={{
                        spaContext,
                        history,
                        httpClient,
                        sessionStore,
                        rpcClient,
                        grpcClient,
                      }}
                    />
                  </Route>
                  <Route path="/applications/:applicationCode/rich-media">
                    <ApplicationConnect
                      widgetState="show"
                      name="@pushwoosh/richmedia"
                      isBroken={Boolean(brokenMap['@pushwoosh/richmedia'])}
                      customProps={{
                        spaContext,
                        history,
                        httpClient,
                        sessionStore,
                        rpcClient,
                        grpcClient,
                        grpcBridge,
                      }}
                    />
                  </Route>
                  <Route path="/applications/:applicationCode/statistics/analytics-dashboard">
                    <ApplicationConnect
                      widgetState="show"
                      name="@pushwoosh/analytics-dashboard"
                      isBroken={Boolean(brokenMap['@pushwoosh/analytics-dashboard'])}
                      customProps={{
                        spaContext,
                        history,
                        httpClient,
                        sessionStore,
                        rpcClient,
                        grpcClient,
                        grpcBridge,
                        journeyGrpcClient,
                      }}
                    />
                  </Route>
                  {process.env['NODE_ENV'] === 'development' && (
                    <Route path="/_devel_smart_components">
                      <Parcel module="https://localhost:8088/index.js" spaContext={spaContext} />
                    </Route>
                  )}
                  <Route path={['/']}>
                    <ApplicationWithWidget
                      widgetState="show"
                      name="@pushwoosh/control-panel"
                      isBroken={Boolean(brokenMap['@pushwoosh/control-panel'])}
                      customProps={{
                        spaContext,
                        history,
                        httpClient,
                        grpcClient,
                        grpcBridge,
                        rpcClient,
                        billingGrpcClient,
                        sessionStore,
                      }}
                    />
                  </Route>
                </Switch>
              </RootContent>
            </RootInner>
          </RootBox>
        </Router>
      </React.StrictMode>
    );
  }
}
